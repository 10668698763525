/** @format */
@import url("https://use.typekit.net/qdl4zrs.css");
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: muli, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

*::-webkit-scrollbar {
  display: none;
}

button {
  cursor: pointer;
}

@media (min-width: 600px) {
  button:hover {
    opacity: 0.9;
    -webkit-transform: translateY(-0.125rem) scale(0.975);
            transform: translateY(-0.125rem) scale(0.975);
  }
}

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.page-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  max-width: 900px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.blur-layer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 2;
}

.corner-action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  outline: none;
  border-radius: 1rem;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
}

.corner-action-btn span {
  font-weight: 600;
  padding: 0rem 0.5rem;
  padding-top: 0.5rem;
}

.corner-action-btn .icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.5rem 0rem;
}

.corner-action-btn .icon-wrapper .icon {
  font-size: 2rem;
}

.corner-action-btn:hover {
  opacity: 0.85;
}

.info-link {
  position: fixed;
  border: none;
  outline: none;
  bottom: 0rem;
  padding: 0.25rem 2rem;
  font-size: 0.85rem;
  text-decoration: none;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  font-weight: 600;
}

.modal {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 3;
}

.modal .info-modal {
  max-width: 400px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .info-modal .title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modal .info-modal .information-box {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal .info-modal .information-box .heading {
  font-size: 1.125rem;
}

.modal .info-modal .information-box .sub-heading {
  margin-top: 1rem;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-weight: 600;
  font-size: 1.25rem;
}

.modal .info-modal .information-box .instruction-list {
  margin-top: 0.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

.modal .info-modal .information-box .instruction-list .instruction {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 1.125rem;
}

.modal .info-modal .information-box .instruction-list .instruction .number {
  padding: 0.15rem 0.5rem;
  border-radius: 2rem;
}

.modal .info-modal .information-box .instruction-list .instruction .text {
  margin-left: 0.5rem;
}

.modal .info-modal .actions {
  padding: 1rem 0rem;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal .info-modal .actions .git-link {
  text-decoration: none;
}

.modal .info-modal .actions .btn-github {
  border: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 0.5rem;
  font-size: 0.85rem;
}

.modal .user-settings-modal {
  max-width: 400px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .user-settings-modal .user-info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.modal .user-settings-modal .user-info .picture {
  height: 5rem;
  width: 5rem;
  border-radius: 5rem;
}

.modal .user-settings-modal .user-info .greeting .label {
  font-size: 1.125rem;
}

.modal .user-settings-modal .user-info .greeting .name {
  font-size: 1.125rem;
  font-weight: 700;
}

.modal .user-settings-modal .actions {
  padding: 1rem 0rem;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal .user-settings-modal .actions .btn-logout {
  color: white;
}

.modal .trip-modal {
  max-width: 400px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .trip-modal .color-picker-box {
  width: 85%;
}

.modal .trip-modal .color-picker-box .color-swatch-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow-x: scroll;
  padding: 1rem 0rem;
  gap: 2rem;
}

.modal .trip-modal .color-picker-box .color-swatch-list .swatch {
  border: none;
  outline: none;
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 2rem;
  margin-bottom: 0.75rem;
}

.modal .person-modal {
  max-width: 400px;
  margin-top: 5rem;
  margin-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .activity-modal {
  max-width: 400px;
  margin-top: 3rem;
  margin-bottom: 10rem;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .activity-modal .participant-toggles {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.125rem;
}

.modal .activity-modal .participant-toggles .participant-toggle-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-radius: 0.75rem;
}

.modal .activity-modal .participant-toggles .participant-toggle-box span {
  font-size: 1.125rem;
  margin-left: 0.75rem;
}

.modal .activity-modal .participant-toggles .participant-toggle-box .toggle-box {
  width: 3rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-right: 0.5rem;
}

.modal .activity-modal .participant-toggles .participant-toggle-box .toggle-box .toggle {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 2rem;
}

.modal .activity-modal .participant-toggles .participant-toggle-box .participating {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.modal .splits-modal {
  max-width: 600px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 85%;
  border-radius: 2rem;
  padding: 1rem 0rem;
  gap: 1.5rem;
}

.modal .splits-modal .total-cost {
  margin-top: -1rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal .splits-modal .total-cost .label {
  font-size: 0.95rem;
}

.modal .splits-modal .total-cost .amount {
  font-weight: 800;
}

.modal .splits-modal .splits-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.modal .splits-modal .splits-list .split {
  width: 90%;
  border-radius: 0.75rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal .splits-modal .splits-list .split .top-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.modal .splits-modal .splits-list .split .top-section .name {
  color: white;
  margin-left: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.75rem 0rem;
}

.modal .splits-modal .splits-list .split .top-section .balance {
  margin-right: 1rem;
  padding: 0.125rem 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  background-color: white;
  border-radius: 2rem;
}

.modal .splits-modal .splits-list .split .bottom-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0.75rem 0rem;
}

.modal .splits-modal .splits-list .split .bottom-section .transaction {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 1.5rem;
  gap: 0.35rem;
}

.modal .splits-modal .splits-list .split .bottom-section .transaction .label {
  font-size: 1.125rem;
}

.modal .splits-modal .splits-list .split .bottom-section .transaction .name {
  font-size: 1.125rem;
  font-weight: 800;
}

.modal .splits-modal .splits-list .split .bottom-section .transaction .amount {
  font-size: 1.125rem;
  font-weight: 700;
}

.modal .title span {
  font-size: 1.5rem;
  font-weight: 600;
}

.modal .input-form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

.modal .input-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  gap: 0.125rem;
}

.modal .input-box .caption {
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-weight: 700;
  font-size: 0.75rem;
}

.modal .input-box .input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.25rem 0rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 0.75rem;
  padding: 0.5rem 0.5rem;
}

.modal .drop-down-selector {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.75rem;
  padding: 0rem 0.5rem;
}

.modal .drop-down-selector .selection-btn {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.modal .drop-down-selector .selection-btn span {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
}

.modal .drop-down-selector .selection-btn .icon {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
}

.modal .drop-down-selector .selection-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.modal .drop-down-selector .selection-list .selection-option {
  border: none;
  outline: none;
  font-size: 1.125rem;
  padding-left: 0.5rem;
}

.modal .drop-down-selector:hover .icon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.modal .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 85%;
}

.modal .actions .divider {
  height: 2rem;
  width: 0.25rem;
  border-radius: 2rem;
}

.modal .actions button {
  border: none;
  outline: none;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  font-size: 1.125rem;
  cursor: pointer;
}

.modal .actions button:hover {
  opacity: 0.85;
}

.modal .actions .btn-delete .link {
  text-decoration: none;
}

.sign-on-page .page-container {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sign-on-page .images {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sign-on-page .images .img {
  border-radius: 1rem;
}

.sign-on-page .images .imgLeft {
  z-index: 1;
  width: 10rem;
  height: auto;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.sign-on-page .images .imgMiddle {
  z-index: 0;
  width: 8rem;
  height: auto;
  -webkit-transform: rotate(-20deg) translateY(-1rem) translateX(3rem) scale(1.25);
          transform: rotate(-20deg) translateY(-1rem) translateX(3rem) scale(1.25);
}

.sign-on-page .images .imgRight {
  z-index: 1;
  width: 10rem;
  height: auto;
  -webkit-transform: rotate(15deg) translateY(10rem) translateX(2rem);
          transform: rotate(15deg) translateY(10rem) translateX(2rem);
}

.sign-on-page .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: -2.5rem;
  margin-left: 1rem;
}

.sign-on-page .title .title-font {
  font-size: 5rem;
  font-family: bely-display, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.sign-on-page .caption .caption-font {
  font-size: 1.25rem;
}

.sign-on-page .sign-on-btn {
  border: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  padding: 0.25rem 1rem;
  margin-bottom: 3rem;
  border-radius: 2rem;
  cursor: pointer;
}

.sign-on-page .sign-on-btn .logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2rem;
}

.sign-on-page .sign-on-btn .logo-wrapper .logo {
  font-size: 1.25rem;
  border-radius: 2rem;
}

.sign-on-page .sign-on-btn .text {
  font-size: 1.25rem;
}

.sign-on-page .sign-on-btn:hover {
  opacity: 0.9;
}

@media (min-width: 600px) {
  .sign-on-page .images {
    gap: 5rem;
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
  .sign-on-page .images .imgRight {
    -webkit-transform: rotate(15deg) translateX(-5rem) translateY(15rem) scale(1.35);
            transform: rotate(15deg) translateX(-5rem) translateY(15rem) scale(1.35);
  }
}

.home-page .header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 3rem;
  padding: 1.5rem 0rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.home-page .header .title {
  font-size: 2rem;
  font-family: bely-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 1rem;
}

.home-page .header .profile-photo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
}

.home-page .header .icon-btn {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2rem;
}

.home-page .header .icon-btn .icon {
  font-size: 2rem;
  border-radius: 2rem;
}

.home-page .no-trips {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 5rem;
  gap: 0.5rem;
}

.home-page .trips-list-view {
  margin: 2rem 0rem;
  margin-bottom: 10rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 95%;
}

.home-page .trips-list-view .title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.home-page .trips-list-view .title span {
  font-size: 1.75rem;
  font-weight: 800;
  font-style: normal;
}

.home-page .trips-list-view .trips-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.75rem;
  margin: 0.5rem 0rem;
}

.home-page .trips-list-view .trips-list .trip-box {
  width: 100%;
  text-decoration: none;
}

.home-page .trips-list-view .trips-list .trip-box button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-height: 8rem;
  border: none;
  outline: none;
  border-radius: 0.75rem;
}

.home-page .trips-list-view .trips-list .trip-box button span {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  font-weight: 800;
  color: white;
}

@media (min-width: 600px) {
  .home-page .trips-list-view .trips-list .trip-box button:hover {
    -webkit-transform: translateY(-0.125rem) scale(0.995);
            transform: translateY(-0.125rem) scale(0.995);
  }
}

.trip-page .header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 3rem;
  padding: 1.5rem 0rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.trip-page .header .title {
  font-size: 2rem;
  font-family: bely-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-right: 1rem;
}

.trip-page .header .icon-btn {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2rem;
  margin-left: 1rem;
  text-decoration: none;
}

.trip-page .header .icon-btn .icon {
  font-size: 2rem;
  border-radius: 2rem;
}

.trip-page .edit-trip-btn {
  margin-top: 2rem;
  border-radius: 1rem;
  padding: 0.25rem 2rem;
}

.trip-page .trip-view-section {
  margin-top: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 95%;
}

.trip-page .trip-view-section .section-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.trip-page .trip-view-section .section-header span {
  font-size: 1.75rem;
  font-weight: 700;
  width: 100%;
}

.trip-page .trip-view-section .section-header .add-btn {
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 800;
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.trip-page .people-section .people-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1.5rem;
  overflow-x: scroll;
  padding-left: 2rem;
}

.trip-page .people-section .people-list .person {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  margin: 1rem 0rem;
  margin-bottom: 2rem;
}

.trip-page .people-section .people-list .person span {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

.trip-page .activities-section {
  margin-bottom: 5rem;
}

.trip-page .activities-section .activities-list {
  margin-top: 1rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
}

.trip-page .activities-section .activities-list .activity {
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  border: none;
  outline: none;
  border-radius: 1.75rem;
}

.trip-page .activities-section .activities-list .activity .top-section {
  margin-top: 1rem;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.trip-page .activities-section .activities-list .activity .top-section .title span {
  color: white;
  font-size: 1.25rem;
  font-weight: 800;
}

.trip-page .activities-section .activities-list .activity .top-section .cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.trip-page .activities-section .activities-list .activity .top-section .cost span {
  background-color: white;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
}

.trip-page .activities-section .activities-list .activity .divider {
  width: 90%;
  height: 0.125rem;
  border-radius: 2rem;
}

.trip-page .activities-section .activities-list .activity .bottom-section {
  margin-bottom: 1rem;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.trip-page .activities-section .activities-list .activity .bottom-section .paid-by {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 0.5rem;
}

.trip-page .activities-section .activities-list .activity .bottom-section .paid-by .label {
  font-size: 1.125rem;
  font-weight: 800;
  color: black;
}

.trip-page .activities-section .activities-list .activity .bottom-section .paid-by .payer {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

.trip-page .activities-section .activities-list .activity .bottom-section .participant-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.trip-page .activities-section .activities-list .activity .bottom-section .participant-list .participant {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

@media (min-width: 600px) {
  .trip-page .activities-section .activities-list .activity:hover {
    -webkit-transform: translateY(-0.125rem) scale(0.995);
            transform: translateY(-0.125rem) scale(0.995);
  }
}

.trip-page .get-started-section {
  gap: 1rem;
}

.trip-page .get-started-section span {
  font-weight: 600;
}
