/** @format */

@import url("https://use.typekit.net/qdl4zrs.css");

// ********************************
// VARS
// ********************************

// ********************************
// COMMON
// ********************************
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: muli, sans-serif;
  font-weight: 400;
  font-style: normal;
  transition: 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
}

button {
  cursor: pointer;

  @media (min-width: 600px) {
    &:hover {
      opacity: 0.9;
      transform: translateY(-0.125rem) scale(0.975);
    }
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  max-width: 900px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  scroll-behavior: smooth;
}

.blur-layer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  z-index: 2;
}

.corner-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  outline: none;
  border-radius: 1rem;
  position: fixed;
  bottom: 2rem;
  right: 1rem;

  span {
    font-weight: 600;
    padding: 0rem 0.5rem;
    padding-top: 0.5rem;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem;

    .icon {
      font-size: 2rem;
    }
  }

  &:hover {
    opacity: 0.85;
  }
}

.info-link {
  position: fixed;
  border: none;
  outline: none;
  bottom: 0rem;
  padding: 0.25rem 2rem;
  font-size: 0.85rem;
  text-decoration: none;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  font-weight: 600;
}

.modal {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 3;

  .info-modal {
    max-width: 400px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .information-box {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .heading {
        font-size: 1.125rem;
      }

      .sub-heading {
        margin-top: 1rem;
        align-self: flex-start;
        font-weight: 600;
        font-size: 1.25rem;
      }

      .instruction-list {
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;

        .instruction {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          font-size: 1.125rem;

          .number {
            padding: 0.15rem 0.5rem;
            border-radius: 2rem;
          }

          .text {
            margin-left: 0.5rem;
          }
        }
      }
    }

    .actions {
      padding: 1rem 0rem;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .git-link {
        text-decoration: none;
      }

      .btn-github {
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        font-size: 0.85rem;
      }
    }
  }

  .user-settings-modal {
    max-width: 400px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;

    .user-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;

      .picture {
        height: 5rem;
        width: 5rem;
        border-radius: 5rem;
      }

      .greeting {
        .label {
          font-size: 1.125rem;
        }
        .name {
          font-size: 1.125rem;
          font-weight: 700;
        }
      }
    }

    .actions {
      padding: 1rem 0rem;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .btn-logout {
        color: white;
      }
    }
  }

  .trip-modal {
    max-width: 400px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;

    .color-picker-box {
      width: 85%;

      .color-swatch-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: scroll;
        padding: 1rem 0rem;
        gap: 2rem;
        // padding: 2rem 5rem;

        .swatch {
          border: none;
          outline: none;
          min-height: 3rem;
          min-width: 3rem;
          border-radius: 2rem;
          margin-bottom: 0.75rem;
        }
      }
    }
  }

  .person-modal {
    max-width: 400px;
    margin-top: 5rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;
  }

  .activity-modal {
    max-width: 400px;
    margin-top: 3rem;
    margin-bottom: 10rem;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;

    .participant-toggles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.125rem;

      .participant-toggle-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0.5rem;
        border-radius: 0.75rem;

        span {
          font-size: 1.125rem;
          margin-left: 0.75rem;
        }

        .toggle-box {
          width: 3rem;
          border-radius: 2rem;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 0.5rem;

          .toggle {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 2rem;
          }
        }
        .participating {
          justify-content: flex-end;
        }
      }
    }
  }

  .splits-modal {
    max-width: 600px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
    border-radius: 2rem;
    padding: 1rem 0rem;
    gap: 1.5rem;

    .total-cost {
      margin-top: -1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .label {
        font-size: 0.95rem;
      }

      .amount {
        font-weight: 800;
      }
    }

    .splits-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;

      .split {
        width: 90%;
        border-radius: 0.75rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .top-section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            color: white;
            margin-left: 1rem;
            font-size: 1.25rem;
            font-weight: 600;
            padding: 0.75rem 0rem;
          }
          .balance {
            margin-right: 1rem;
            padding: 0.125rem 0.5rem;
            font-size: 1.125rem;
            font-weight: 600;
            background-color: white;
            border-radius: 2rem;
          }
        }

        .bottom-section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0.75rem 0rem;

          .transaction {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 1.5rem;
            gap: 0.35rem;

            .label {
              font-size: 1.125rem;
            }
            .name {
              font-size: 1.125rem;
              font-weight: 800;
            }
            .amount {
              font-size: 1.125rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .title {
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .input-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
  }

  .input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    gap: 0.125rem;

    .caption {
      align-self: flex-start;
      font-weight: 700;
      font-size: 0.75rem;
    }

    .input {
      width: 100%;
      border: none;
      outline: none;
      padding: 0.25rem 0rem;
      font-size: 1.25rem;
      font-weight: 600;
      border-radius: 0.75rem;
      padding: 0.5rem 0.5rem;
    }
  }

  .drop-down-selector {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.75rem;
    padding: 0rem 0.5rem;

    .selection-btn {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.5rem 0.5rem;
      }

      .icon {
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.5rem 0.5rem;
      }
    }

    .selection-list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.25rem;
      margin-bottom: 1rem;

      .selection-option {
        border: none;
        outline: none;
        font-size: 1.125rem;
        padding-left: 0.5rem;
      }
    }

    &:hover {
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 85%;

    .divider {
      height: 2rem;
      width: 0.25rem;
      border-radius: 2rem;
    }

    button {
      border: none;
      outline: none;
      padding: 0.25rem 1rem;
      border-radius: 2rem;
      font-size: 1.125rem;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }

    .btn-delete {
      .link {
        text-decoration: none;
      }
    }
  }
}

// ********************************
// SIGN ON
// ********************************
.sign-on-page {
  .page-container {
    justify-content: space-between;
  }

  .images {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: translateY(-2rem);

    .img {
      border-radius: 1rem;
    }

    .imgLeft {
      z-index: 1;
      width: 10rem;
      height: auto;
      transform: rotate(15deg);
    }
    .imgMiddle {
      z-index: 0;
      width: 8rem;
      height: auto;
      transform: rotate(-20deg) translateY(-1rem) translateX(3rem) scale(1.25);
    }
    .imgRight {
      z-index: 1;
      width: 10rem;
      height: auto;
      transform: rotate(15deg) translateY(10rem) translateX(2rem);
    }
  }

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: -2.5rem;
    margin-left: 1rem;

    .title-font {
      font-size: 5rem;
      font-family: bely-display, sans-serif;
      font-weight: 400;
      font-style: normal;
    }
  }

  .caption {
    .caption-font {
      font-size: 1.25rem;
    }
  }

  .sign-on-btn {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.25rem 1rem;
    margin-bottom: 3rem;
    border-radius: 2rem;
    cursor: pointer;

    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2rem;
      .logo {
        font-size: 1.25rem;
        border-radius: 2rem;
      }
    }
    .text {
      font-size: 1.25rem;
    }
    &:hover {
      opacity: 0.9;
    }
  }

  @media (min-width: 600px) {
    .images {
      gap: 5rem;
      transform: scale(1.25);

      .imgRight {
        transform: rotate(15deg) translateX(-5rem) translateY(15rem) scale(1.35);
      }
    }
  }
}

// ********************************
// HOME
// ********************************
.home-page {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 1.5rem 0rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .title {
      font-size: 2rem;
      font-family: bely-display, sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-left: 1rem;
    }

    .profile-photo {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 2.5rem;
    }

    .icon-btn {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2rem;

      .icon {
        font-size: 2rem;
        border-radius: 2rem;
      }
    }
  }

  .no-trips {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    gap: 0.5rem;
  }

  .trips-list-view {
    margin: 2rem 0rem;
    margin-bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        font-size: 1.75rem;
        font-weight: 800;
        font-style: normal;
      }
    }

    .trips-list {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.75rem;
      margin: 0.5rem 0rem;

      .trip-box {
        width: 100%;
        text-decoration: none;

        button {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          min-height: 8rem;
          border: none;
          outline: none;
          border-radius: 0.75rem;

          span {
            padding: 0.5rem 1rem;
            font-size: 2rem;
            font-weight: 800;
            color: white;
          }

          @media (min-width: 600px) {
            &:hover {
              transform: translateY(-0.125rem) scale(0.995);
            }
          }
        }
      }
    }
  }
}

// ********************************
// TRIP
// ********************************
.trip-page {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 1.5rem 0rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .title {
      font-size: 2rem;
      font-family: bely-display, sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-right: 1rem;
    }

    .icon-btn {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2rem;
      margin-left: 1rem;
      text-decoration: none;

      .icon {
        font-size: 2rem;
        border-radius: 2rem;
      }
    }
  }

  .edit-trip-btn {
    margin-top: 2rem;
    border-radius: 1rem;
    padding: 0.25rem 2rem;
  }

  .trip-view-section {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;

    .section-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 700;
        width: 100%;
      }

      .add-btn {
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 800;
        border-radius: 0.75rem;
        padding: 0.25rem 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .people-section {
    .people-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.5rem;
      overflow-x: scroll;
      padding-left: 2rem;

      .person {
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        border-radius: 0.75rem;
        margin: 1rem 0rem;
        margin-bottom: 2rem;

        span {
          font-size: 1.125rem;
          font-weight: 600;
          color: white;
        }
      }
    }
  }

  .activities-section {
    margin-bottom: 5rem;
    .activities-list {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.5rem;

      .activity {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        border: none;
        outline: none;
        border-radius: 1.75rem;

        .top-section {
          margin-top: 1rem;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            span {
              color: white;
              font-size: 1.25rem;
              font-weight: 800;
            }
          }
          .cost {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              background-color: white;
              font-size: 1.25rem;
              font-weight: 600;
              border-radius: 2rem;
              padding: 0.25rem 1rem;
            }
          }
        }

        .divider {
          width: 90%;
          height: 0.125rem;
          border-radius: 2rem;
        }

        .bottom-section {
          margin-bottom: 1rem;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .paid-by {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;

            .label {
              font-size: 1.125rem;
              font-weight: 800;
              color: black;
            }
            .payer {
              font-size: 1.125rem;
              font-weight: 600;
              color: white;
            }
          }

          .participant-list {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .participant {
              font-size: 1.125rem;
              font-weight: 600;
              color: white;
            }
          }
        }

        @media (min-width: 600px) {
          &:hover {
            transform: translateY(-0.125rem) scale(0.995);
          }
        }
      }
    }
  }

  .get-started-section {
    gap: 1rem;
    span {
      font-weight: 600;
    }
  }
}
